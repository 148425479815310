<template>
  <div>
    <div>
        <div v-if="fetchingUser || result" style="border-radius:2rem;" class="card text-center loading-viewport">
            <is-loading v-if="fetchingUser" :box="true" />
            <img v-if="result && result.id_card" :src="absoluteUrl(result.id_card)" class="card-img-top" alt="Group head Id">
            <div v-else class="card-body py-4 position-relative">
                <p class="mb-0">Loading guest...</p>
            </div>
            <div v-if="result" class="d-grid mt-3">
                <button  @click.prevent="resetCamera()" class="btn btn-primary" type="button"> Scan </button>
            </div>
        </div>
        <div v-else>
            <qrcode-stream @decode="onDecode" />
        </div>
    </div>
  </div>
</template>
<script>
import { QrcodeStream } from "vue3-qrcode-reader";
import IsLoading from '@/components/IsLoading.vue';

export default {
    data(){
        return {
            fetchingUser: false, 
            popupModalShow: false,
            result: null
        }
    },
    components: {
        QrcodeStream,
        IsLoading,
    },
    methods: {
        onDecode(result) {
            this.fetchingUser = true
            this.$http.get(`/meeting-guests/attendance?tkn=${result}&mtid=${this.$route.params.meetingId}`)
            .then((response) => {
                this.fetchingUser = false
                if(response.data.success){
                    this.result = response.data.data;
                }
            })
        },
        resetCamera(){
            this.result = null;
            this.fetchingUser = false
        },
        initializeAll(){
            this.result = null
        }
    },
}
</script>

<style>

</style>