
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of guests</h5>
      <div class="row gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4 d-flex">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                      :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
          <div class="dropdown no-arrow ms-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click="selectAllRows()" class="dropdown-item" type="button">
                  {{isSelectAll? 'Invert selection' : 'Select all' }}
                </button>
              </li>
              <li @click.prevent="exportClients()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(check)="data">
                <div style="width:3rem;" class="form-check text-center me-auto">
                  <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.guest.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.guest.first_name}} {{ data.item.guest.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.guest.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(lga)="data">
                <div style="min-width:5rem;" v-if="data.item.guest.lga" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.guest.lga.name }}</span>
                </div>
              </template>
              <template v-slot:cell(ward)="data">
                <div style="min-width:6rem;" v-if="data.item.guest.ward" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.guest.ward.name }}</span>
                </div>
              </template>
              <template v-slot:cell(unit)="data">
                <div style="min-width:7rem;" v-if="data.item.guest.unit" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.guest.unit.name }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-if="data.item.guest.is_verified" class="badge bg-success font-sm"> Verified</span>
                  <span v-else class="badge bg-warning font-sm">Pending</span>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div style="min-width:5rem;" class="">
                  <button type="button" @click="deleteItem(data.item.id)"
                   class="btn p-2 d-inline text-decoration-none btn-link text-danger">
                    <i class="ri-delete-bin-2-line"></i></button>
                  <button type="button" @click="navigateTo(data.item.guest.id)" 
                    class="btn p-2 d-inline text-decoration-none btn-link text-primary">
                    <i class="ri-eye-line"></i></button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "meeting-members",
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: null, label: "All", },
        { value: "1", label: "Precent" },
        { value: "0", label: "Absent" }
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "Check"
        },
        {
            key: "image",
            label: "Image",
        },
        {
            key: "name",
            label: "Name",
        },
        {
            key: "mobile",
            label: "Phone",
        },
        {
            key: "lga",
            label: "LGA",
        },
        {
            key: "ward",
            label: "Ward",
        },
        {
            key: "unit",
            label: "Unit",
        },
        {
            key: "status",
            label: "Status",
        },
        "action"
      ],
      pageData:{
        data: []
      },
      selected: [],
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
                    meeting_id: this.$route.params.meetingId }
      if(this.defaultStatus){ payload.is_present = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/meeting-guests?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete this guest from this meeting",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/meeting-guests/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              const ItemIndex = this.pageData.data.findIndex((item) => item.id == itemId)
              this.pageData.data.splice(ItemIndex, 1);
            }
          })
        }
      });
    },
    exportClients() {
      const payload = {
        url: `/meeting-guests/export-excel?meeting_id=${this.$route.params.meetingId}`,
        filename: 'meeting-guests_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
  },
  created() {
    this.fetchItems()
  }
}

</script>

