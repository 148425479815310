
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="meeting" class="col-12">
      <h5 class="mb-3">Show meeting</h5>
      <div class="card mb-4 overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-10 col-sm-8">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="me-3">
                  <div class="avatar img-thumbnail flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{meeting.title.charAt(0).toUpperCase()}}
                    </div>
                  </div>
                </div> 
                <div class="">
                  <div class="friendly-id mb-1">
                    {{meeting.friendly_id}}
                  </div>
                  <div class="">
                    <strong class="me-1">Status:</strong>
                    <span v-html="statusBadge(meeting.status)"> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-end">
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a @click="popupModalQrCode = true" class="dropdown-item"
                      href="javascript:void(0)" >
                    Scan QR code </a>
                  </li>
                  <li>
                    <a @click="popupModalShow = true" class="dropdown-item"
                      href="javascript:void(0)" >
                    Update meeting </a>
                  </li>
                  <li>
                    <a @click.prevent="deleteItem()"
                    class="dropdown-item" href="javascript:void(0)">
                    Delete meeting </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <h4 class="mb-0">{{meeting.title}}</h4>
              <p v-if="meeting.description" class="mb-0 mt-2 pt-1 text-muted">{{meeting.description}}</p>
            </div>
          </div>          
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <statistic-apex-count
            title="Attendees"
            :count-end="meeting.attendees_count"
            :total="meeting.guests_count"
            :figure="meeting.attendees_count"
          />
        </div>
        <div class="col-md-6">
          <statistic-apex-count
            title="Absentees"
            :count-end="meeting.absentees_count"
            :total="meeting.guests_count"
            :figure="meeting.absentees_count"
            />
        </div>
      </div>
      <div class="row mb-2 gy-2">
        <div class="col-12">
          <meeting-guests />
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update meeting"
    title-class="fs-6" @hide="initializeAll()">
    <meeting-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="meeting" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
  <b-modal v-model="popupModalQrCode" hide-footer centered
    title="Scan QR Code"
    title-class="fs-6" @hide="initializeAll()">
    <qr-code-scanner v-if="popupModalQrCode" />
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import MeetingGuests from './MeetingGuests.vue';
import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';
import MeetingForm from '@/components/forms/MeetingForm.vue';
import QrCodeScanner from '@/components/forms/QrCodeScanner.vue';

export default {
  name: "meeting-show",
  components:{
    IsLoading,
    MeetingGuests,
    StatisticApexCount,
    MeetingForm,
    QrCodeScanner
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      popupModalQrCode: false,
      meeting: null,
    }
  },
  watch: {
    '$route.params.meetingId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.meeting, item)
    },
    fetchItem(){
      this.isLoading = true
       this.$http.get(`/meetings/${this.$route.params.meetingId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.meeting = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete an meeting permanently",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/meetings/${this.meeting.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/meetings'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
      this.popupModalQrCode = false
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

